/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { injectIntl } from "gatsby-plugin-intl"
import styled from "styled-components"
import Header from "./Header"
import Footer from "./Footer"
import {
    ViewportWrapper,
    PageWrapper,
    HeaderWrapper,
    ContentWrapper
} from "./Styled"

const LandingContentWrapper = styled(ContentWrapper)`
    text-align: center;
`

const Layout = ({ children, intl, pageInfo }) => {
    return (
        <ViewportWrapper>
            <PageWrapper>
                <HeaderWrapper>
                    <Header showLogo={pageInfo.pageName !== "index" } />
                </HeaderWrapper>
                <LandingContentWrapper>{children}</LandingContentWrapper>
                <Footer></Footer>
            </PageWrapper>
        </ViewportWrapper>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired
}

export default injectIntl(Layout)
