import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby-plugin-intl"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Container, Nav, Navbar } from "react-bootstrap"
import styled from "styled-components"
import LoginModal from "./LoginModal"

const BrandWrapper = styled.h1`
    padding: 31px 0;
    text-align: right;
`

const Header = ({ showLogo }) => {
    const [showLogin, setShowLogin] = useState(false)

    const onLoginClick = () => {
        setShowLogin(true)
    }

    const onLoginHide = () => {
        setShowLogin(false)
    }

    return (
        <Navbar sticky="top" expand="lg" id="site-navbar" className="w-100">
            <Container>
                {/* <Container> */}
                {showLogo && (
                    <Link to="/">
                        {" "}
                        <Navbar.Brand as="span">
                            <StaticImage
                                src="../images/logo-300dpi.png"
                                alt="Satimoto"
                                placeholder="none"
                                height={128}
                            />
                            <BrandWrapper className="text-gradient">
                                Satimoto
                            </BrandWrapper>
                        </Navbar.Brand>
                    </Link>
                )}
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <Nav.Link onClick={onLoginClick}>
                        Login
                    </Nav.Link>
                </Navbar.Collapse>
            </Container>
            <LoginModal show={showLogin} onHide={onLoginHide}></LoginModal>
        </Navbar>
    )
}

Header.defaultProps = {
    showLogo: false
}

Header.propTypes = {
    showLogo: PropTypes.bool
}

export default Header
