import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter, faGithub } from "@fortawesome/free-brands-svg-icons"
import { Link } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import {
    FooterWrapper,
    SocialWrapper,
    SocialLinkWrapper
} from "../components/Styled"
//import LanguageSwitcher from "./LanguageSwitcher"

const Footer = () => {
    return (
        <FooterWrapper>
            <SocialWrapper>
                <SocialLinkWrapper>
                    <Link to="https://twitter.com/SatimotoApp" target="_blank">
                        <FontAwesomeIcon icon={faTwitter} />
                    </Link>
                </SocialLinkWrapper>
                <SocialLinkWrapper>
                    <Link to="https://github.com/satimoto" target="_blank">
                        <FontAwesomeIcon icon={faGithub} />
                    </Link>
                </SocialLinkWrapper>
            </SocialWrapper>
            {/*<LanguageSwitcher/>*/}
        </FooterWrapper>
    )
}

export default injectIntl(Footer)
