import styled, { css } from "styled-components"
import { Link } from "gatsby"

export const StyledWrapper = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    place-content: start;
`

export const StyledFullScreenWrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    overflow: hidden;
`

export const StyledCenterWrapper = styled.div`
    margin: 0px auto;
`

export const StyledContentCenterWrapper = styled.div`
    display: flex;
    text-align: center;
    color: #ccc;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

export const StyledContentWrapperLeft = styled(StyledContentCenterWrapper)`
    max-width: 960px;
    margin: auto 53% auto auto;
`

export const StyledImageWrapper = styled.div`
    max-width: ${(props) => props.maxWidth || 300}px;
    margin: 0 auto 1.45rem;
`

export const StyledLink = styled(Link)`
    margin-left: 0.32rem;
`

const MediaWrapper = css`
    @media (min-width: 414px) {
        height: 100%;
        min-height: 100vh;
        box-pack: justify;
        justify-content: space-between;    
    }
`

export const ViewportWrapper = styled.div`
    ${MediaWrapper}
    width: 100%;
    min-width: 280pt;
    height: auto;
    min-height: 100vh;
    box-pack: justify;
    justify-content: space-between;
    background: #303030;
`

export const PageWrapper = styled.div`
    ${MediaWrapper}
    width: 100%;
    height: auto;
    display: flex;
    margin: 0px auto;
    max-width: 1024px;
    flex-direction: column;
    box-pack: start;
    justify-content: flex-start;
`

export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    box-align: center;
    align-items: center;
    box-pack: justify;
    justify-content: space-between;
`

export const ContentWrapper = styled.div`
    display: flex;
    box-align: center;
    align-items: center;
    flex-direction: column;
    box-pack: center;
    justify-content: center;
`

export const ContentItemWrapper = styled.div`
    @media (min-width: 880px) {
        flex-direction: row;
    }
    display: flex;
    box-align: center;
    align-items: center;
    flex-direction: column;
    box-pack: center;
    justify-content: center;
`

/*
 * Footer Styles
 */

export const FooterWrapper = styled.div`
    @media (min-width: 414px) {
        flex-direction: row;
        box-pack: justify;
        justify-content: space-between;
    }
    display: flex;
    box-align: center;
    align-items: center;
    flex-direction: column;
    box-pack: center;
    justify-content: center;
    padding: 20px 30px;
`

export const SocialWrapper = styled.div`
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
`

export const SocialLinkWrapper = styled.div`
    @media (min-width: 414px) {
        width: 24px
    }
    width: 18px;
    opacity: 0.8;
    margin-right: 10px;
    transition: opacity 0.15s ease-in-out 0s;
`
