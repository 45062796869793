import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { Alert, Button, Modal } from "react-bootstrap"
import { usePageVisibility } from "react-page-visibility"

import QRCode from "qrcode.react"
import { useApi } from "../providers/api"

const LoginModal = ({ intl, show, onHide }) => {
    const { state, getQRCode, verifyCode } = useApi()
    const isVisible = usePageVisibility()
    const [error, setError] = useState(null)

    const verify = useCallback(async () => {
        if (isVisible) {
            const { verified, error } = await verifyCode()

            setError(error)

            if (verified) {
                onHide()
            }
        }
    }, [isVisible, onHide, verifyCode])

    useEffect(() => {
        const update = async () => {
            if (show) {
                await getQRCode()
            }
        }
        update()
    }, [show, getQRCode])

    useEffect(() => {
        if (show && isVisible) {
            const timer = setInterval(verify, 5000)

            return () => clearInterval(timer)
        }
    }, [isVisible, show, verify])

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Body className="text-center">
                {error && <Alert variant="danger">{error.message}</Alert>}
                {state.qrCode && <QRCode size={256} value={state.qrCode} />}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    <FormattedMessage
                        id="components.login_modal.close_button"
                        defaultMessage="Close"
                    />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

LoginModal.defaultProps = {
    show: false,
    onHide: () => {}
}

LoginModal.propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func
}

export default injectIntl(LoginModal)
